.checkBox {
    @apply relative flex cursor-pointer items-baseline pl-0 text-sm text-common-black;
}
.checkBox input {
    @apply absolute top-0 left-0 h-full w-full cursor-pointer appearance-none border-interface-300 opacity-0 focus:border-interface-300 focus:shadow-none focus:ring-0;
}
.checkBox.legCheckBox input {
    @apply absolute top-0 left-0 h-full w-full cursor-pointer appearance-none border-interface-300 opacity-0 focus:border-interface-300 focus:shadow-none focus:ring-0;
}
.checkBox .checkmark {
    @apply relative top-0.5 mr-2 h-[16px] w-[16px] flex-shrink-0 rounded border border-interface-300;
}
.checkBox .flightCheckmark {
    @apply m-0 mx-auto;
}
.checkBox .checkmark i {
    @apply absolute  left-[1.5px] top-[2.5px] hidden text-[9px] text-common-white;
}
.checkBox input:checked + .checkmark {
    @apply border-primary-500 bg-primary-500;
}
.checkBox input:checked + .checkmark i {
    @apply block;
}
.checkBox.disabled,
.flightId.checkBox input[disabled] {
    @apply pointer-events-none cursor-not-allowed opacity-50;
}
.checkBox input[disabled] + span + span {
    @apply cursor-not-allowed opacity-50;
}
.checkBox input:checked[disabled] + .checkmark {
    @apply cursor-not-allowed opacity-50;
}
